import React,{useState, useEffect} from 'react';
import logo from './logo.png';
// import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import Config from './config.json';
 
function Header() {
   const [categories, setCategories] = useState([]);

   useEffect(() => {
      getCategories();
    }, []);
    const getCategories = async () => {
      const fetchUrl = Config.api_url + '/category';
      const response = await fetch(fetchUrl);
      const res = await response.json();
      setCategories(res.catData);
    }

    return (
      <div className="main-header">
      <script crossOrigin="true" src="..."></script>
      <div className="container">
         <div className="row">
            <div className="col-xs-12 col-lg-2 col-sm-12 col-md-3 logo-holder">
               <div className="logo">
                  <Link to={"/"}>
                  <img src={logo} alt="logo" /> </Link> 
               </div>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-5 col-xs-12 top-search-holder">
               <div className="search-area">
                  <form>
                     <div className="control-group">
                        <input className="search-field" placeholder="Search here..." />
                     </div>
                  </form>
               </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 navmenu">
            <div className="yamm navbar navbar-default" role="navigation">
               <div className="nav-bg-className">
                  <div className="navbar-collapse" id="mc-horizontal-menu-collapse">
                  <div className="nav-outer">
                     <ul className="nav navbar-nav">
                        {categories && categories.map(details => {
                            return (
                              <li key={details._id} className="dropdown"> 
                                 <a href={"?category=" + details.title} className="dropdown-toggle" data-hover="dropdown"
                                    >{details.title}
                                 </a>
                              </li>
                              // <div key={details._id}>
                              //   <li style={{ padding: '5px', cursor: 'pointer' }}>
                              //     <div onClick={() => handleClick(details.title)}>
                              //       {/* <Link to={"/coupons?category=" + details.title} style={{ color: 'black', textDecoration: 'none' }}>{details.title}</Link> */}
                              //       <Link to={"?category=" + details.title} style={{ color: 'black', textDecoration: 'none' }}>{details.title}</Link>
                              //     </div>
                              //   </li>
                              // </div>
                            )
                          })
                          }
                     </ul>
                     <div className="clearfix"></div>
                  </div>
                  </div>
               </div>
            </div>
            </div>
         </div>
      </div>
   </div>
    );
 };
  
 export default Header;