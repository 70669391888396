import React from "react";

function Footer() {

    return (
        <footer>  
            <div className="copyright-bar">
                <div className="container">
                {/* <div className="col-xs-12 col-sm-12 no-padding social">
                    <ul className="link">
                    <li className="fb"><a target="_blank" rel="nofollow" href="/" title="Facebook"></a></li>
                    <li className="tw"><a target="_blank" rel="nofollow" href="/" title="Twitter"></a></li>
                    <li className="googleplus"><a target="_blank" rel="nofollow" href="/" title="GooglePlus"></a></li>
                    <li className="rss"><a target="_blank" rel="nofollow" href="/" title="RSS"></a></li>
                    <li className="pintrest"><a target="_blank" rel="nofollow" href="/" title="PInterest"></a></li>
                    <li className="linkedin"><a target="_blank" rel="nofollow" href="/" title="Linkedin"></a></li>
                    <li className="youtube"><a target="_blank" rel="nofollow" href="/" title="Youtube"></a></li>
                    </ul>
                </div> */}
                <div className="col-xs-12 col-sm-12 no-padding copyright">&copy; 2022 Marvel Coupon. All Rights Reserved. </div>
                
                </div>
            </div>
        </footer>
    );

};

export default Footer;