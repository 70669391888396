import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import './home.css';
import Header from '../header';
import Carousel from 'react-bootstrap/Carousel';
// import Carousel from "react-multi-carousel";
import Config from '../config.json';
import { useSearchParams, useLocation, } from "react-router-dom";
import {isMobile} from 'react-device-detect';
function Home() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [obj, setObj] = useState(1);

  const [values, setValues] = useState([]);

  const [lastestHeading, setLatestHeading] = useState("Latest Coupons");

  const location = useLocation();
  let [searchParams,] = useSearchParams(location.search);

  const handleShow = (dta) => {
    setShow(true);
    setObj(dta);
  };


  var requestOptions = {
    // headers: myHeaders,
  };
  //latest
  const url = Config.api_url + '/coupons?limit=4';
  const fetchLatestCoupons = async () => {
    const response = await fetch(url, requestOptions);
    const res = await response.json();
    setValues(res.data);
    // fetch(url).
    // then(res => res.json()).
    // then(result => setValues(result.couponData));

  }

  const dateFormat = (cDate) => {
    const months = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'Aug',
      8: 'Sept',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    }
    if (cDate === 'Today!') {
      const d = new Date();
      // console.log("cDate => ",d, " <<<>>>>> ", cDate);
      const year = d.getFullYear();
      const date = d.getDate();
      const monthName = months[d.getMonth()];
      const formatted = date + ' ' + monthName + ', ' + year;
      // console.log(" <<<>>>>> ", formatted);
      return formatted;
    } else {
      const d = new Date(cDate);
      // console.log("cDate => ",d, " <<<>>>>> ", cDate);
      const year = d.getFullYear();
      const date = d.getDate();
      const monthName = months[d.getMonth()];
      const formatted = date + ' ' + monthName + ', ' + year;
      // console.log(" <<<>>>>> ", formatted);
      return formatted;
    }
  }

  useEffect(() => {
    fetchLatestCoupons();
    let category = searchParams.get("category") ? searchParams.get("category") : "";
    let country = searchParams.get("country") ? searchParams.get("country") : "";
    let lastestHeading = "Latest Coupons";
    if (category) {
      if (["hotel", "flight"].includes(category)) {
        category = category + "s"
      }
      lastestHeading = lastestHeading + " for " + category.replace(/^./, category[0].toUpperCase());
    }
    if (country) {
      let countries = {
        "US": "USA", "BG": "Bulgaria", "DE": "Germany", "TR": "Turkey", "EG": "Egypt", "AE": "UAE",
        "KSA": "Saudi Arabia", "IN": "India", "CH": "Switzerland", "IT": "Italy", "FR": "France", "BH": "Bahrain", "GB": "UK"
      }
      lastestHeading = lastestHeading + " in " + countries[country];
    }
    setLatestHeading(lastestHeading)

    // }
  }, []);

  const extractPercentAndAmountChild = (str, char, pos) => {
    // console.log(str)
    if (str.indexOf(char) !== -1 && pos === "before") {
      let res = str.split(char);
      let num = res[0].trim(" ").split(" ");
      return num[num.length - 1] + char
    }
    if (str.indexOf(char) !== -1 && pos === "after") {
      let res = str.split(char);
      let num = res[1].split(" ");
      return char + num[0]
    }
  }

  const extractPercentAndAmount = (str) => {
    let strings = [str]
    let before = ["%", "€"]
    let after = ["Rs. ", "Rs", "₹"]
    for (var i = 0; i < strings.length; i++) {
      for (var j = 0; j < before.length; j++) {
        // console.log(strings[i],before[j])
        // console.log(strings[i].includes(before[j]))
        if (strings[i].includes(before[j])) {
          return extractPercentAndAmountChild(strings[i], before[j], "before") + " off"
        }
      }
      for (var k = 0; k < after.length; k++) {
        // console.log(strings[i],after[j])
        // console.log(strings[i].includes(after[j]))
        if (strings[i].includes(after[k])) {
          return extractPercentAndAmountChild(strings[i], after[k], "after") + " off"
        }
      }
    }
    return "Offer"

  }

  return (
    <div>

      <Header />
      <section className="section coupons-section">
        <div className="container">
          <h3 className="section-title">{lastestHeading}</h3>
          <br />
          <div className="coupons-deals">
            {values && !isMobile &&
              <Carousel controls={false} indicators={true} >
                <Carousel.Item>
                  <div className="item item-carousel col-md-12 row">
                    {values && values.map((value, index) => {
                      if ( index > 1) {
                        return (
                          <div className="products col-md-6" key={value._id}>
                            <div className="product">
                              <div className="product-image">
                                <div className="image card">
                                  
                                  <div className='image-child'><img className="Image-responsive" src={value.s3ImageUrl ? value.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} onError={
    () => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'}  alt="" /></div>
                                  
                                  <div className="brand">
                                    {value.title}
                                  </div> <br />
                                  <h3 className="name">
                                    <span>{value.description}</span>
                                  </h3>
                                </div>
                              </div>
                              <div className="product-info text-left">
                                <div className="discount"> <span>{extractPercentAndAmount(value.description ? value.description : value.title)}</span></div>
                                <div className="show-code cpn-shwcd"><span onClick={() => handleShow(value)}>Show Code</span>
                                </div><p className="exp-date"> {value.expiryDate ? <span><i className="fa fa-clock-o"></i>&nbsp;Expires on <br />{dateFormat(value.expiryDate)}</span> : null}</p>
                              </div>
                            </div>
                          </div>
                        )
                       }
                      else {
                        return (<></>);
                      }
                    })}
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="item item-carousel col-md-12 row">
                    {values && values.map((value, index) => {
                      if (index > 1) {
                        return (
                          <div className="products col-md-6" key={value._id}>
                            <div className="product">
                              <div className="product-image">
                                <div className="image card">
                                  <div className='image-child'><img className="Image-responsive" src={value.s3ImageUrl ? value.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} onError={
    () => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'} alt="" /></div>
                                  
                                  <div className="brand">
                                    {value.title}
                                  </div> <br />
                                  <h3 className="name">
                                    <span>{value.description}</span>
                                  </h3>
                                </div>
                              </div>
                              <div className="product-info text-left">
                                <div className="discount"> <span>{extractPercentAndAmount(value.description ? value.description : value.title)}</span></div>
                                <div className="show-code cpn-shwcd"><span onClick={() => handleShow(value)}>Show Code</span>
                                </div><p className="exp-date"> {value.expiryDate ? <span><i className="fa fa-clock-o"></i>&nbsp;Expires on <br />{dateFormat(value.expiryDate)}</span> : null}</p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      else {
                        return (<></>)
                      }
                    })}
                  </div>
                </Carousel.Item>
              </Carousel>
            }
            {values && isMobile &&
              <Carousel controls={false} indicators={true} interval={null} >
                <Carousel.Item>
                  <div className="item  item-carousel col-md-12 row">
                    {values && values.map((value, index) => {
                      if (index === 1 ) {
                        return (
                          <div className="products-mobile col-md-6" key={value._id}>
                            <div className="logo-store-name">
                                <div className="tp-store-logo"><img data-lazy-src={value.s3ImageUrl ? value.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} alt="" src={value.s3ImageUrl ? value.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} onError={
    () => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'} className="couponlogo"/></div>
                                <div className="tp-store-name"> <b>{value.title}</b></div>
                            </div>
                            <div className="text-center offer-value"><b> {extractPercentAndAmount(value.description ? value.description : value.title)}</b></div>
                            <div className="text-center descriptiontext-cover">{value.description}</div>
                            <div className="shw-code">
                            <div className="show-code cpn-shwcd cpn-shwcdMobile" onClick={() => handleShow(value)}>Show Code</div>
                            </div>
                            <div className="text-center expiry">
                              {value.expiryDate ? (
                                <span>
                                  <i className="fa fa-clock-o"></i>
                                  &nbsp;Expires on {" "}
                                  {dateFormat(value.expiryDate)}
                                </span>
                              ) : <span></span>
                              }
                            </div> </div>
                        )
                       }
                      else {
                        return (<></>);
                      }
                    })}
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="item  item-carousel col-md-12 row">
                    {values && values.map((value, index) => {
                      if (index === 2) {
                        return (
                          <div className="products-mobile col-md-6" key={value._id}>
                            <div className="logo-store-name">
                                <div className="tp-store-logo"><img data-lazy-src={value.s3ImageUrl ? value.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} alt="" src={value.s3ImageUrl ? value.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} onError={
    () => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'} className="couponlogo"/></div>
                                <div className="tp-store-name"> <b>{value.title}</b></div>
                            </div>
                            <div className="text-center offer-value"><b> {extractPercentAndAmount(value.description ? value.description : value.title)}</b></div>
                            <div className="text-center descriptiontext-cover">{value.description}</div>
                            <div className="shw-code">
                            <div className="show-code cpn-shwcd cpn-shwcdMobile" onClick={() => handleShow(value)}>Show Code</div>
                            </div>
                            <div className="text-center expiry">
                              {value.expiryDate ? (
                                <span>
                                  <i className="fa fa-clock-o"></i>
                                  &nbsp;Expires on {" "}
                                  {dateFormat(value.expiryDate)}
                                </span>
                              ) : <span><p></p></span>}
                            </div> </div>   
                         )
                      }
                      else {
                        return (<></>)
                      }
                    })}
                  </div>
                </Carousel.Item>
              </Carousel>

            }
          </div>

        </div>

        <Modal size="lg" fullscreen="lg-down" show={show} onHide={handleClose} className="modal fade">
          <Modal.Header closeButton>
            <Modal.Title>{obj.title}</Modal.Title>
          </Modal.Header>
          <div className="modal-content cpn-body">
            <img className="img-responsive cpn-img" src={obj.s3ImageUrl ? obj.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} onError={
    () => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'} alt="" />
            <h3 className="mb-20 cpn-name">{obj.description}</h3>
            <div className="coupon-content cpn-content">Not applicable to ICANN fees, taxes, transfers,or gift cards. Cannot be used in conjunction with any other offer, sale, discount or promotion.
              <span style={{ color: '#ed6663', display: 'block', marginTop: '10px' }}><span style={{ color: '#ed6663', textDecoration: 'underline' }}>Visit our Store</span></span>
            </div>
            <div><h6 className="color-mid cpn-mid">Click below to get your coupon code</h6>
              <div className="copy-coupon-wrap">
                <input type="text" value={obj.couponCode} id="X132-17GT-OL571" className="coupon-code" />
              </div>
            </div>
          </div>

          <div className="cpn-footer">
            <div className='cpn-modal-footer'>
              <h4>Subscribe to Mail</h4>
              <p>Get our Daily email newsletter with Special Services, Updates, Offers and more!</p>
              <form id="mc4wp-form-2" className="mc4wp-form mc4wp-form-1257" method="post" data-id="1257" data-name="dealdots">
                <div className="mc4wp-form-fields">
                  <div id="container_form_news">
                    <div id="container_form_news2">
                      <input type="email" id="newsletter1" name="EMAIL" placeholder="Your email address" required />
                      <a  className="button subscribe"><span>Subscribe</span></a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>

      </section>

      {/* <Section /> */}
      {/* <Footer /> */}
    </div>
  );

};


export default Home;