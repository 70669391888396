import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Home from './home/home';
// import Coupons from './coupons';
// import Category from './category/category';
import Section from './section/section';

import {
  BrowserRouter,
  Routes,
  Route,
  // Navigate
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Section />}></Route>
          {/* <Route path='*' element={<Navigate to='/' />} /> */}
          {/* <Route path='/coupons' element={<Category />}></Route> */}
          {/* <Route path='/home' element={<Section />}></Route> */}
          {/* <Route path='/coupons' element={<Coupons />}>
            <Route path=':category' element={<Category />}></Route>
          </Route> */}
          <Route path='/coupons/*' element={<Section />}></Route>
          {/* <Route path='/coupons/:category/:country' element={<Section />}></Route> */}
          {/* <Route path='/:category' element={<Category />}></Route> */}

        </Routes>
      </BrowserRouter>
      {/* <Home /> */}
    </div>
  );
}

export default App;
