import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import "./section.css";
// import Carousel from "react-bootstrap/Carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
// import catArr from '../data.json';
import {isMobile} from 'react-device-detect';
// import Dropdown from "react-bootstrap/Dropdown";
// // import Accordion from 'react-bootstrap/Accordion';
// import Form from "react-bootstrap/Form";

import Modal from "react-bootstrap/Modal";

import Config from "../config.json";

import InfiniteScroll from "react-infinite-scroll-component";

// import Category from "../category/category";

import {
  // BrowserRouter as Router,
  // Routes,
  // Route,
  // Link,
  useSearchParams,
  // useParams,
  useLocation,
} from "react-router-dom";

import Home from "../home/home";

import Footer from "../footer/footer";

// const style = {
//   height: 30,
//   border: "1px solid green",
//   margin: 6,
//   padding: 8
// };

function Section() {
  const [show, setShow] = useState(false);
  const [obj, setObj] = useState(1);

  const [data, setData] = useState([]);

  const [pageId, setPageId] = useState(Number);

  const [total, setTotal] = useState(Number);

  const [state, setState] = useState(true);

  const [cat, setCat] = useState("");
  const [country, setCountry] = useState("");

  const [, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  // const searchParams = useParams();
  // console.log(countries)
  let [searchParams] = useSearchParams(location.search);

  const handleClose = () => setShow(false);
  const handleShow = (dta) => {
    setShow(true);

    setObj(dta);
  };

  var requestOptions = {
    // method: 'GET',
    // headers: myHeaders,
  };

  const url = Config.api_url + "/coupons";

  // const getUrl =  window.location.href;

  // var cN = getUrl.split("/");

  // const catName = cN[4];

  const dateFormat = (cDate) => {
    const months = {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "June",
      6: "July",
      7: "Aug",
      8: "Sept",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    };
    if (cDate === "Today!") {
      const d = new Date();
      // console.log("cDate => ",d, " <<<>>>>> ", cDate);
      const year = d.getFullYear();
      const date = d.getDate();
      const monthName = months[d.getMonth()];
      const formatted = date + " " + monthName + ", " + year;
      // console.log(" <<<>>>>> ", formatted);
      return formatted;
    } else {
      const d = new Date(cDate);
      // console.log("cDate => ",d, " <<<>>>>> ", cDate);
      const year = d.getFullYear();
      const date = d.getDate();
      const monthName = months[d.getMonth()];
      const formatted = date + " " + monthName + ", " + year;
      // console.log(" <<<>>>>> ", formatted);
      return formatted;
    }
  };
  // const load = useRef(true);
  useEffect(() => {
    // if(load.current)
    // {
    // load.current = false;

    setData([]);
    setTotal(0);
    let category = searchParams.get("category") ? searchParams.get("category") : "";
    let country = searchParams.get("country") ? searchParams.get("country") : "";
    // console.log(country);
    setCat(category);
    setCountry(country);
    getCategories();
    getCountries();
    // fetchCount();
    fetchCoupons(1, category, country);
    // }
  }, []);

  const getCategories = async () => {
    const fetchUrl = Config.api_url + "/category";
    const response = await fetch(fetchUrl);
    const res = await response.json();
    setCategories(res.catData);
  };
  const getCountries = async () => {
    const fetchUrl = Config.api_url + "/country";
    const response = await fetch(fetchUrl);
    const res = await response.json();
    setCountries(res.catData);
  };

  // const fetchCount = async () => {

  //   const total = await fetch(url + '/total');
  //   const resTotal = await total.json();
  //   setTotal(resTotal.count);

  // }

  // const catArr = ['Pizza','Travel','Carrental','Restaurants','Fastfood','Shoes','Xbox','Weathertech','Flight','Freeprints','Jewelry','Tv','Clothing','Bathandbody','Hotel','Phone','Electronics','Playstation','Movietheaters','Flowers','Makeup','Photo','Fooddelivery','Furniture','Toys','Iphone','Beauty','Birkenstock','Nespresso','Marathon','Videogames','Books','Pets','Tools','Baby'];

  const fetchCoupons = async (page, category, country) => {
    // console.log("SKPDATA",skip);
    setPageId(page);
    if (page === 1) {
      setData([]);
      setTotal(0);
    }
    var fetchUrl = url + "?"
    if (category) {
      fetchUrl = fetchUrl + "&category=" + category  //"&page=" + page;
    }
    if (country) {
      fetchUrl = fetchUrl + "&country=" + country //"&page=" + page;
    }
    fetchUrl = fetchUrl + "&page=" + page;
    const response = await fetch(fetchUrl, requestOptions);
    const res = await response.json();
    if (res.data.length) {
      if (page > 1) {
        setData(data.concat(res.data));
        setTotal(res.totalCount);
      } else {
        setData(res.data);
        setTotal(res.totalCount);
      }
    }
  };

  const extractPercentAndAmountChild = (str, char, pos) => {
    // console.log(str)
    if (str.indexOf(char) !== -1 && pos === "before") {
      let res = str.split(char);
      let num = res[0].trim(" ").split(" ");
      return num[num.length - 1] + char
    }
    if (str.indexOf(char) !== -1 && pos === "after") {
      let res = str.split(char);
      let num = res[1].split(" ");
      return char + num[0]
    }
  }

  const extractPercentAndAmount = (str) => {
    let strings = [str]
    let before = ["%", "€"]
    let after = ["Rs. ", "Rs", "₹"]
    for (var i = 0; i < strings.length; i++) {
      for (var j = 0; j < before.length; j++) {
        // console.log(strings[i],before[j])
        // console.log(strings[i].includes(before[j]))
        if (strings[i].includes(before[j])) {
          return extractPercentAndAmountChild(strings[i], before[j], "before") + " off"
        }
      }
      for (var k = 0; k < after.length; k++) {
        // console.log(strings[i],after[j])
        // console.log(strings[i].includes(after[j]))
        if (strings[i].includes(after[k])) {
          return extractPercentAndAmountChild(strings[i], after[k], "after") + " off"
        }
      }
    }
    return "Offer"

  }


  const fetchMoreData = () => {
    if (total <= pageId * 20) {
      setState(false);
      // console.log(pageId+20);
    } else {
      fetchCoupons(pageId + 1, cat, country);
    }
  };

  // const handleClick = (cat) => {
  //   setCat(cat);
  //   setData([]);
  //   setTotal(0);
  //   fetchCoupons(1, cat);
  //   // setCat("/coupons/" + cat);
  // };
  // const slides = document.querySelectorAll(".owl-item");

  // // loop through slides and set each slides translateX property to index * 100%
  // slides.forEach((slide, indx) => {
  //   slide.style.transform = `translateX(${indx * 10}%)`;

  //   // slide.style.transition= `all 200ms ease 0s`;
  // });



  const recipes = [...Array(countries.length).keys()]//[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const goToPageOnClick = (target_url) => {
    navigate(target_url)
    navigate(0)
  }
  let countriesArr = {
    "US": "USA", "BG": "Bulgaria", "DE": "Germany", "TR": "Turkey", "EG": "Egypt", "AE": "UAE",
    "KSA": "Saudi Arabia", "IN": "India", "CH": "Switzerland", "IT": "Italy", "FR": "France", "BH": "Bahrain", "GB": "UK"
  }


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 0
    }
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <button className={currentSlide === 0 ? 'react-multiple-carousel__arrow react-multiple-carousel__arrow--left disable' : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--left'} onClick={() => previous()}></button>
        <button className={'react-multiple-carousel__arrow react-multiple-carousel__arrow--right'}onClick={() => next()} ></button>
        {/* <button onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </button> */}
      </div>
    );
  };
  return (
    <div>
      <Home />
      <div className="body-content">
        <div className=" container cntnr">
          <section>
            <h3 className="opacity-0">countries</h3>
            <div className="featured-product">
              {!isMobile &&
              <Carousel
              arrows={false} 
              renderButtonGroupOutside={true}
              customButtonGroup={ countries.length > 0 ? isMobile ? <></> :  <ButtonGroup />  : <></>}
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                // showDots={true}
                infinite={true}
                partialVisible={false}
              // dotListClass="custom-dot-list-style"
              >
                {recipes.map((imageUrl, index) => {
                  return (

                    <Card key={index} style={{ width: 'auto' }}>

                      <div className="slider" key={index}>
                        <img role="button" src={`assets/images/country/${countries[index].code.toLowerCase()}.svg`} alt="movie" onClick={() => goToPageOnClick("?category=" + cat + "&country=" + countries[index].code)} />
                      </div>
                      <Card.Body onClick={() => goToPageOnClick("?category=" + cat + "&country=" + countries[index].code)} >
                        <Card.Title role="button" className={country === countries[index].code ? 'text-center card-title h6 selected-country' : 'text-center card-title h6'}>
                          {countries[index].name}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  );
                })}
              </Carousel>
              }
              {isMobile &&
                <div className="dropdown dropdown-country"> 
                <button className="dropbtn dropbtn-country"> 
                  {country ? "Selected Country ("+countriesArr[country]+")" :"Select Country"}
                </button> 
                <div className="dropdown-content"> 
                {recipes.map((imageUrl, index) => {
                 
                 return( <div className={country === countries[index].code ? 'selected-country-dd' : ''} onClick={() => goToPageOnClick("?category=" + cat + "&country=" + countries[index].code)}> 
                  <img src= {`assets/images/country/${countries[index].code.toLowerCase()}.svg`} width="25" height="15" alt=""/> 
                  {" " + countries[index].name}
                  </div> )
                  })}
      
                   
                </div> 
            </div> 
              }
            </div>
          </section>


          <div className="row">
            <div className="col-md-12 rht-col">
              <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={state}
                loader={<span className="loader"></span>              }
                // height={100%}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div className="category-product coupons-section coupons-section-inner">
                  <div className="row coupons-deals">
                    {data &&
                      data.map((details) => {
                        return (
                          <div
                            className="col-sm-6 col-md-6 col-lg-6 fadeInUp"
                            key={details._id}
                          >
                            <div className="item">
                              {!isMobile &&
                              <div className="products">
                                <div className="product">
                                  <div className="product-image">
                                    <div className="image card">
                                      <div className="image-child">
                                        <Image
                                          className="Image-responsive"
                                          src={details.s3ImageUrl ? details.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"}
                                          onError={() => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'}
                                          alt="" />
                                      </div>
                                      <div className="brand">
                                        {details.title}
                                      </div>{" "}
                                      <br />
                                      <h3 className="name">
                                        {details.description}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="product-info text-left">
                                    <div className="discount">
                                      {" "}
                                      <span> {extractPercentAndAmount(details.description ? details.description : details.title)}</span>
                                    </div>
                                    <div
                                      className="show-code cpn-shwcd"
                                      onClick={() => handleShow(details)}
                                    >
                                      Show Code
                                    </div>
                                    <p className="exp-date">
                                      {" "}
                                      {details.expiryDate ? (
                                        <span>
                                          <i className="fa fa-clock-o"></i>
                                          &nbsp;Expires on <br />{" "}
                                          {dateFormat(details.expiryDate)}
                                        </span>
                                      ) : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              }
                              {isMobile &&
                              
                              <div className="products-mobile">
                     
                                    <div className="logo-store-name">
                                      <div className="tp-store-logo"><img data-lazy-src={details.s3ImageUrl ? details.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} alt="" src={details.s3ImageUrl ? details.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"} onError={
    () => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'} className="couponlogo"/></div>
                                      <div className="tp-store-name"> <b>{details.title}</b></div>
                                    </div>
                                    <div className="text-center offer-value"><b> {extractPercentAndAmount(details.description ? details.description : details.title)}</b>
                                    </div>
                                    <div className="text-center descriptiontext">
                                    {details.description}
                                    </div>
                                    <div className="shw-code">
                                      <div className="show-code cpn-shwcd cpn-shwcdMobile" onClick={() => handleShow(details)}>Show Code</div>
                                    </div>
                                    <div className="text-center expiry">
                                      {details.expiryDate ? (
                                        <span>
                                          <i className="fa fa-clock-o"></i>
                                          &nbsp;Expires on {" "}
                                          {dateFormat(details.expiryDate)}
                                        </span>
                                      ) : null}
                                    </div>
                                
                              </div>
                              }
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </InfiniteScroll>
            </div>

            {/* <div className="col-md-3 sidebar">
              <div className="side-menu side-menu-inner animate-dropdown outer-bottom-xs">
                <div className="head">Travel Guides</div>
                <nav className="yamm megamenu-horizontal">
                  <ul className="nav">
                    <li className="dropdown menu-item">
                      <Dropdown>
                        <ul>
                          
                        </ul>
                      </Dropdown>
                    </li>
                  </ul>
                </nav>
              </div>
            </div> */}
          </div>
        </div>

        <Modal
          size="lg"
          fullscreen="lg-down"
          show={show}
          onHide={handleClose}
          className="modal fade"
        >
          <Modal.Header closeButton>
            <Modal.Title>{obj.title}</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>  */}
          <div className="modal-content cpn-body">
            <Image
              className="Image-responsive cpn-Image"
              onError={() => this.img.src = 'https://marvelcoupon.com/media/images/logo/marvel-coupon.png'}
              src={obj.s3ImageUrl ? obj.s3ImageUrl:"https://marvelcoupon.com/media/images/logo/marvel-coupon.png"}
              alt=""
            />
            <h3 className="mb-20 cpn-name">{obj.description}</h3>
            <div className="coupon-content cpn-content">
              Not applicable to ICANN fees, taxes, transfers,or gift cards.
              Cannot be used in conjunction with any other offer, sale, discount
              or promotion.
              <span
                style={{
                  color: "#ed6663",
                  display: "block",
                  marginTop: "10px",
                }}
              >
                {/* <span style={{color: '#ed6663', textDecoration:'underline'}}>Visit our Store</span> */}
              </span>
            </div>
            <div>
              <h6 className="color-mid cpn-mid">
                Click below to get your coupon code
              </h6>
              <div className="copy-coupon-wrap">
                <input
                  type="text"
                  value={obj.couponCode}
                  id="X132-17GT-OL571"
                  className="coupon-code"
                />
              </div>
            </div>
          </div>

          <div className="cpn-footer">
            <div className="cpn-modal-footer">
              <h4>Subscribe to Mail</h4>
              <p>
                Get our Daily email newsletter with Special Services, Updates,
                Offers and more!
              </p>
              <form
                id="mc4wp-form-2"
                className="mc4wp-form mc4wp-form-1257"
                method="post"
                data-id="1257"
                data-name="dealdots"
              >
                <div className="mc4wp-form-fields">
                  <div id="container_form_news">
                    <div id="container_form_news2">
                      <input
                        type="email"
                        id="newsletter1"
                        name="EMAIL"
                        placeholder="Your email address"
                        required
                      />
                      <button type="submit" className="button subscribe">
                        <span>Subscribe</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* </Modal.Body> */}
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default Section;
